import React, { useEffect, useState } from "react";
import classes from "./Toggle.module.css";

const Toggle = ({ showImage, darkMode, toggleSwitch }) => {
  const [toggle, setToggle] = useState(darkMode);
  const toggleHandler = () => {
    setToggle((state) => !state);
    toggleSwitch(toggle);
  };
  useEffect(() => {
    setToggle(darkMode);
  }, [darkMode]);
  return (
    <div
      className={`${classes.toggle} ${toggle ? classes.dark : ""}`}
      onClick={toggleHandler}
    >
      <div className={`${classes.toggleDot}`}></div>
      {showImage && (
        <div className={classes.togImage}>
          <img
            src={
              darkMode ? "/assets/images/themedark.png" : "/assets/images/theme.png"
            }
            alt="theme"
          />
        </div>
      )}
    </div>
  );
};

export default Toggle;
