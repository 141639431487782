import { createSlice } from "@reduxjs/toolkit";

const initialRenderState = {
  fileName: "",
  activeStep: 0,
  stepCompleted: -1,
  taskId: "",
};

const renderSlice = createSlice({
  name: "render",
  initialState: initialRenderState,
  reducers: {
    setFileName(state, action) {
      state.fileName = action.payload;
    },
    setStep(state, action) {
      state.activeStep = action.payload;
    },
    setStepCompleted(state, action) {
      state.stepCompleted = action.payload;
    },
    setTaskId(state, action) {
      state.taskId = action.payload;
    },
  },
});

export const { setFileName, setStep, setStepCompleted, setTaskId } =
  renderSlice.actions;

export default renderSlice.reducer;
