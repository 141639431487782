import React, { useState, useEffect } from "react";
import classes from "./Otp.module.css";
import Button from "../../../UI/Button/Button";
import OtpInput from "../../../OtpInput/OtpInput";

const Otp = ({ goTo }) => {
  const [time, setTime] = useState(60);
  const [otpResent, setOtpResent] = useState(false);

  const handleSubmit = () => {};

  const handleResend = () => {
    setTime(60);
    setOtpResent(true);
  };

  useEffect(() => {
    let timer;
    if (time > 0 && otpResent) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setOtpResent(false);
    }

    return () => clearInterval(timer);
  }, [time, otpResent]);

  useEffect(() => {
    handleResend();
  }, []);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      <div className={classes.head}>Verify OTP</div>
      <div className={classes.subHead}>
        Enter OTP sent to davis99davis@gmai.com
      </div>
      <div className={classes.modbody}>
        <div className={classes.otpWrap}>
          <OtpInput length={6}></OtpInput>
        </div>
        <div className={classes.counter}>{formatTime(time)}</div>
        <div className={classes.resend}>
          OTP not received? <span onClick={handleResend}>Resend now</span>
        </div>
        <div className={classes.btnWrapper}>
          <Button
            full={true}
            onClick={() => {
              goTo("forgot");
            }}
          >
            Verify OTP
          </Button>
        </div>
        <div className={classes.option}>
          Got your password?{" "}
          <span
            onClick={() => {
              goTo("login");
            }}
          >
            Login
          </span>
        </div>
      </div>
    </>
  );
};

export default Otp;
