import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/login",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/register",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (responseData) => {
        return responseData.data || responseData;
      },
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApi;
