import React from 'react';
import classes from "./Label.module.css";

const Label = ({ htmlFor, value, theme = "normal" }) => {
  return (
    <label className={`${classes.label} ${classes[theme]}`} htmlFor={htmlFor}>
      {value}
    </label>
  );
};

export default Label;
