import { createSlice } from "@reduxjs/toolkit";

const initialWalletState = {
  walletAddress: "",
  tokenAddress: [],
  balance: 0,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialWalletState,
  reducers: {
    setAddress(state, action) {
      sessionStorage.setItem("pub", action.payload);
      state.walletAddress = action.payload;
    },
    setTokenAddress(state, action) {
      sessionStorage.setItem("tokenAddress", JSON.stringify(action.payload));
      state.tokenAddress = action.payload;
    },
    setTokenBalance(state, action) {
      state.balance = action.payload;
    },
  },
});

export const { setAddress, setTokenAddress, setTokenBalance } =
  walletSlice.actions;

export default walletSlice.reducer;
