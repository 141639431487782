import React from "react";
import classes from "./Modal.module.css";
import ReactDOM from "react-dom";
const ModalContent = ({ children, closeBtn, show, closeMethod, overflow }) => {
  return (
    <div
      className={`${classes.modalOuter} ${show && classes.active} ${
        overflow && classes.overflowVisible
      }`}
    >
      <div className={classes.overlay} onClick={closeMethod}></div>
      <div className={classes.modalWrap}>
        {closeBtn && (
          <div className={classes.close} onClick={closeMethod}>
            <img src="/assets/images/close.png" alt="close" />
          </div>
        )}
        <div className={classes.modalBody}>{children}</div>
      </div>
    </div>
  );
};

const Modal = ({ children, closeBtn, show, closeMethod, overflow }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <ModalContent
          children={children}
          closeBtn={closeBtn}
          show={show}
          closeMethod={closeMethod}
          overflow={overflow}
        />,
        document.getElementById("modal")
      )}
    </>
  );
};

export default Modal;
