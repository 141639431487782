import { createSlice } from "@reduxjs/toolkit";

const initialTemplateState = {
  details: {
    name: "",
    version: "",
    description: "",
    image_path_with_tag: "",
    docker_options: "",
    launch_mode: "",
    docker_entry_point_tags: "",
    extra_filters: "",
    docker_repo_server: "",
    docker_repo_username: "",
    docker_repo_password: "",
    template_name: "",
    template_description: "",
    include_readme: false,
    public_template: false,
    template_type: "predefined",
  },
  addedCustomTemplate: false,
  defaultTemplate: {},
};

const templateSlice = createSlice({
  name: "template",
  initialState: initialTemplateState,
  reducers: {
    selectedTemplate(state, action) {
      state.details = action.payload;
    },
    setAddedCustomTemplate(state, action) {
      state.addedCustomTemplate = action.payload;
    },
    setDefaultTemplateStore(state, action) {
      state.defaultTemplate = action.payload;
    },
  },
});

export const { selectedTemplate, setAddedCustomTemplate, setDefaultTemplateStore } =
  templateSlice.actions;

export default templateSlice.reducer;
