// import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import Toggle from "../UI/Toggle/Toggle";
import Notification from "../Notification/Notification";
import Profile from "../Profile/Profile";
import classes from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useGetBalanceMutation } from "../../services/api/wallet";
import { setTokenBalance } from "../../store/wallet";
import useTheme from "../../hooks/use-theme";

const Header = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const tokenBalance = useSelector((state) => state.wallet.balance);
  const [getBalance, { data, error }] = useGetBalanceMutation();

  const navigate = useNavigate();
  const goTo = (route) => {
    navigate(route);
  };

  const { theme, toggleTheme } = useTheme(); // Use the custom hook

  useEffect(() => {
    if (token) {
      getBalance({
        token_name: "Depined",
      });
    }
  }, [getBalance, token]);

  useEffect(() => {
    if (data && data.data) {
      dispatch(setTokenBalance(data.data));
    } else if (error) {
    }
  }, [data, error, dispatch]);

  return (
    <>
      <div className={classes.heightCompensator}></div>
      <header className={classes.header}>
        <img
          onClick={() => {
            goTo("/instance");
          }}
          className={classes.logo}
          src={
            theme == "dark"
              ? "/assets/images/depined/logoWhite.png"
              : "/assets/images/depined/logo.png"
          }
          alt="logo"
        />
        {token && (
          <div className={classes.middle}>
            <span className={classes.credit}>
              <span className={classes.creditLabel}>Credit Left</span>:{" "}
              {tokenBalance} $DEPIN
            </span>
          </div>
        )}

        <div className={classes.right}>
          <Toggle
            showImage={true}
            darkMode={theme == "dark"}
            toggleSwitch={toggleTheme}
          />
          <Notification theme={theme} />
          <Profile />
        </div>
      </header>
    </>
  );
};

export default Header;
