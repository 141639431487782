import classes from "./Register.module.css";
import Input from "../../../UI/Input/Input";
import Label from "../../../UI/Label/Label";
import Button from "../../../UI/Button/Button";
import { useRegisterMutation } from "../../../../services/api/authApi";
import { showToast } from "../../../../store/toast";
import { useDispatch } from "react-redux";
import React, { useState } from "react";

const Register = ({ goTo }) => {
  const dispatch = useDispatch();
  const [register, result] = useRegisterMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const changeInputHandler = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "password2":
        setPassword2(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (!email) {
      dispatch(
        showToast({
          message: "Email is required",
          type: "error",
          timeout: 5000,
        })
      );
      return;
    }
    if (!password) {
      dispatch(
        showToast({
          message: "Password is required",
          type: "error",
          timeout: 5000,
        })
      );
      return;
    }
    if (password != password2) {
      dispatch(
        showToast({
          message: "Passwords doesnt match",
          type: "error",
          timeout: 5000,
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: "Signing up...",
        type: "warning",
        timeout: 15000,
        id: 10, //default id for loading
      })
    );
    const data = {
      email: email,
      password: password,
    };
    register(data)
      .unwrap()
      .then((payload) => {
        goTo("login");
        dispatch(
          showToast({
            message: "Successfully signed up",
            type: "success",
            timeout: 5000,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  return (
    <>
      <div className={classes.head}>Create your Account</div>
      <div className={classes.modbody}>
        {/* <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="username" value="Username" />
            <Input
              name="username"
              type="text"
              placeholder="Eg: katherine_Acengage"
            />
          </div>
        </div> */}
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="email" value="Email" />
            <Input
              name="email"
              type="email"
              placeholder="Eg: kat@gmail.com"
              value={email}
              changeInputHandler={changeInputHandler}
            />
          </div>
        </div>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="password" value="Password" />
            <Input
              name="password"
              type="password"
              placeholder="Enter password"
              value={password}
              changeInputHandler={changeInputHandler}
            />
          </div>
        </div>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="password2" value="Confirm Password" />
            <Input
              name="password2"
              type="password"
              placeholder="Re-enter password"
              value={password2}
              changeInputHandler={changeInputHandler}
            />
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <Button
            full={true}
            onClick={() => {
              handleSubmit();
              // goTo("otp");
            }}
          >
            Register
          </Button>
        </div>
        <div className={classes.or}>
          <div className={classes.bg}>Or</div>
        </div>
        <div className={classes.logBtn}>
          <img src="/assets/images/google.png" alt="google" />
          Create with Google
        </div>
        <div className={classes.option}>
          Existing user?
          <span
            onClick={() => {
              goTo("login");
            }}
          >
            {" "}
            Login
          </span>
        </div>
      </div>
    </>
  );
};

export default Register;
