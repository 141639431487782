import React, { useState } from "react";
import Modal from "../../UI/Modal/Modal";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Otp from "./Otp/Otp";
import Forgot from "./Forgot/Forgot";
import classes from "./LoginRegister.module.css";
import { useSelector } from "react-redux";

const LoginRegisterInstance = ({ show, onClose }) => {
  const theme = useSelector((state) => state.auth.theme);
  const [activeComponent, setActiveComponent] = useState("login");

  const renderComponent = () => {
    switch (activeComponent) {
      case "login":
        return <Login goTo={(val) => setActiveComponent(val)} />;
      case "register":
        return <Register goTo={(val) => setActiveComponent(val)} />;
      case "otp":
        return <Otp goTo={(val) => setActiveComponent(val)} />;
      case "forgot":
        return <Forgot goTo={(val) => setActiveComponent(val)} />;
      default:
        return null;
    }
  };

  return (
    <Modal show={show} closeBtn={true} closeMethod={onClose}>
      <div className={classes.modalBody}>
        <div className={classes.logo}>
          <img
            src={
              theme == "dark"
                ? "/assets/images/depined/logoImgWhite.png"
                : "/assets/images/depined/logoImg.png"
            }
            alt="logo"
          />
        </div>
        {renderComponent()}
      </div>
    </Modal>
  );
};

export default LoginRegisterInstance;
