import React from "react";
import classes from "./Mobile.module.css";

const MobileUi = () => {
  return (
    <div className={classes.mobileOuter}>
      <div className={classes.logo}>
        <a href="https://www.depined.org/">
          <img src="/assets/images/depined/logoWhite.png" alt="logo" />
        </a>
      </div>
      <div className={classes.middle}>
        <div className={classes.lap}>
          <div className={classes.stars}>
            <div className={classes.star1}>
              <img src="/assets/images/mobile/star1.png" />
            </div>
            <div className={classes.star2}>
              <img src="/assets/images/mobile/star2.png" />
            </div>
            <div className={classes.star3}>
              <img src="/assets/images/mobile/star3.png" />
            </div>
          </div>
          <div className={classes.dotsWrap}>
            {Array.from({ length: 7 }).map((_, i) => (
              <div
                key={i}
                className={`${classes.dot} ${classes[`size${i % 3}`]}`}
              ></div>
            ))}
          </div>
          <img src="/assets/images/mobile/lap.png" alt="desktop" />
          <div className={classes.mobText}>
            Use our product in Desktop device for best experience
          </div>
        </div>
      </div>
      <div className={classes.bottom}>contact@depined.com</div>
    </div>
  );
};

export default MobileUi;
