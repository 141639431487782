import React from "react";
import classes from "./Loading.module.css";
import PageAnimation from "../../PageAnimation/PageAnimation";
const Loading = ({ theme, text = "Loading..." }) => {
  return (
    <PageAnimation>
      <div
        className={`${classes.loading} ${theme == "dark" ? classes.dark : ""}`}
      >
        <div className={classes.logoOuter}>
          <img src="/assets/images/depined/logoSIlver.png" />
          <div>{text}</div>
        </div>
      </div>
    </PageAnimation>
  );
};

export default Loading;
