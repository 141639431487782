import { api } from "./api";

export const templateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplate: builder.query({
      query: (params) =>
        `/api/docker_template?page=${params.page}&limit=${params.limit}&template_type=${params.type}`,
      // providesTags: (result, error, args) => [
      //   ...result.data?.templates?.map((id) => ({ type: "Template", id })),
      // ],
      transformResponse: (responseData) => {
        if (!responseData.data) {
          responseData.data = [];
        }
        return responseData;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
    setDefaultTemplate: builder.mutation({
      query: (data) => ({
        url: `/api/docker_template/default/${data}`,
        method: "POST",
        body: {},
      }),
    }),
    getDefaultTemplate: builder.query({
      query: () => `/api/docker_template/default`,
      transformResponse: (responseData) => {
        if (!responseData.data) {
          responseData.data = [];
        }
        return responseData;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
    getTemplateDetailsById: builder.query({
      query: (id) => `/api/docker_template/${id}`,
      transformResponse: (responseData) => {
        if (!responseData.data) {
          responseData.data = [];
        }
        return responseData;
      },
      keepUnusedDataFor: 0,
      refetchOnMountOrArgChange: true,
    }),
    addTemplate: builder.mutation({
      query: (data) => ({
        url: `/api/docker_template`,
        method: "POST",
        body: data,
      }),
    }),
    updateTemplate: builder.mutation({
      query: (data) => ({
        url: `/api/docker_template/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    updateTemplateImage: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/docker_template/image/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useLazyGetTemplateQuery,
  useLazyGetTemplateDetailsByIdQuery,
  useSetDefaultTemplateMutation,
  useAddTemplateMutation,
  useUpdateTemplateMutation,
  useUpdateTemplateImageMutation,
  useLazyGetDefaultTemplateQuery
} = templateApi;
