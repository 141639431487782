import classes from "./Login.module.css";
import Input from "../../../UI/Input/Input";
import Label from "../../../UI/Label/Label";
import Button from "../../../UI/Button/Button";
import { useLoginMutation } from "../../../../services/api/authApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../store/toast";
import { setCredentials, setUserDetails } from "../../../../store/auth";
import { closeModal } from "../../../../store/auth";
import { useLazyGetProfileQuery } from "../../../../services/api/instanceActions";

const Login = ({ goTo }) => {
  const dispatch = useDispatch();
  const [login, result] = useLoginMutation();
  const [trigger, profileDetails] = useLazyGetProfileQuery();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnText, setBtnText] = useState("Login");

  useEffect(() => {
    if (profileDetails.data) {
      const details = {
        name: profileDetails.data.username,
        email: profileDetails.data.email,
        image: profileDetails.data.profile_path,
      };
      dispatch(setUserDetails(details));
    }
  }, [profileDetails.data]);

  const changeInputHandler = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (!email) {
      dispatch(
        showToast({
          message: "Email is required",
          type: "error",
          timeout: 5000,
        })
      );
      return;
    }
    if (!password) {
      dispatch(
        showToast({
          message: "Password is required",
          type: "error",
          timeout: 5000,
        })
      );
      return;
    }
    dispatch(
      showToast({
        message: "Signing in...",
        type: "warning",
        timeout: 15000,
        id: 10, //default id for loading
      })
    );
    setBtnText("Signing in...");
    const data = {
      email: email,
      password: password,
    };
    login(data)
      .unwrap()
      .then((payload) => {
        dispatch(
          showToast({
            message: "Successfully signed in",
            type: "success",
            timeout: 5000,
          })
        );
        setBtnText("Login");
        dispatch(
          setCredentials({
            user: payload.user_id,
            token: payload.token,
          })
        );
        dispatch(closeModal());
        trigger();
      })
      .catch((error) => {
        setBtnText("Login");
        console.log(error);
      })
      .finally(() => {
        setBtnText("Login");
      });
  };
  return (
    <>
      <div className={classes.head}>Login to your Account</div>
      <div className={classes.modbody}>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="email" value="Email" />
            <Input
              name="email"
              type="email"
              placeholder="Eg: kat@gmail.com"
              value={email}
              changeInputHandler={changeInputHandler}
            />
          </div>
        </div>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="password" value="Password" />
            <Input
              name="password"
              type="password"
              placeholder="Enter password"
              value={password}
              changeInputHandler={changeInputHandler}
            />
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <Button
            full={true}
            onClick={handleSubmit}
            disabled={btnText != "Login"}
          >
            {btnText}
          </Button>
        </div>
        <div className={classes.forgot}>
          <div
            className={classes.forIn}
            onClick={() => {
              goTo("otp");
            }}
          >
            Forgot Password?
          </div>
        </div>
        <div className={classes.or}>
          <div className={classes.bg}>Or</div>
        </div>
        <div className={classes.logBtn}>
          <img src="/assets/images/google.png" alt="google" />
          Login with Google
        </div>
        <div className={classes.option}>
          New user?{" "}
          <span
            onClick={() => {
              goTo("register");
            }}
          >
            Create account
          </span>
        </div>
      </div>
    </>
  );
};

export default Login;
