import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials, setUserDetails } from "../../store/auth";
import { useLazyGetDefaultTemplateQuery } from "../../services/api/templateApi";
import { setDefaultTemplateStore } from "../../store/template";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import MobileUi from "../../components/MobileUi/Mobile";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import classes from "./dashboard.module.css";

const Dashboard = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [trigger, templateDetails] = useLazyGetDefaultTemplateQuery();
  const navigate = useNavigate();

  const setCredentialsFromLocal = () => {
    if (!token) {
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user");
      let userDetails = {};
      try {
        userDetails = JSON.parse(localStorage.getItem("userDet"));
      } catch (e) {
        console.log(e);
      }
      if (token) {
        dispatch(setCredentials({ user: user, token }));
        dispatch(setUserDetails(userDetails));
        trigger()
          .unwrap()
          .then((res) => {
            if (res.status) {
              dispatch(setDefaultTemplateStore(res.data));
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  useEffect(() => {
    setCredentialsFromLocal();
  }, [token]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/instance");
    }
  }, [navigate]);

  return (
    <>
      <ScrollToTop />
      <div className={classes.mobileUi}>
        <MobileUi />
      </div>
      <div className={classes.desktopUi}>
        <Header />
        <div className={classes.bodyWrap}>
          <Sidebar />
          <main className={classes.dashboard}>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
