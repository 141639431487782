import React, { useState } from "react";
import classes from "./Input.module.css";

const Input = ({
  type = "textbox",
  name,
  disabled = false,
  placeholder = "",
  value,
  theme = "normal",
  changeInputHandler,
}) => {
  const [ShowEye, setShowEye] = useState(false);
  const onToggleEye = () => {
    setShowEye((state) => !state);
  };
  return (
    <div className={classes.inputWrap}>
      <input
        className={`${classes.inputBox} ${classes[theme]}`}
        type={type == "password" && ShowEye ? "textbox" : type}
        id={name}
        placeholder={placeholder}
        name={name}
        value={value}
        disabled={disabled}
        onChange={changeInputHandler}
      />
      {type === "password" && (
        <div className={classes.passwordWrap} onClick={onToggleEye}>
          <img src="/assets/images/eye.png" alt="password" />
          {ShowEye && <div className={classes.cross}></div>}
        </div>
      )}
    </div>
  );
};

export default Input;
