import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Dashboard from "./pages/dashboard/dashboard";
import Loading from "./components/UI/Loading/Loading";

const Landing = lazy(() => import("./pages/landing/Landing"));

const MainLanding = lazy(() =>
  import("./pages/landing/MainLanding/MainLanding")
);

const Prompt = lazy(() => import("./pages/dashboard/Prompt/Prompt"));

const Authentication = lazy(() =>
  import("./pages/authentication/Authentication")
);
const Login = lazy(() =>
  import("./components/AuthenticationComponents/Login/Login")
);
const Register = lazy(() =>
  import("./components/AuthenticationComponents/Register/Register")
);
const Forgot = lazy(() =>
  import("./components/AuthenticationComponents/Forgot/Forgot")
);
const MyInstance = lazy(() =>
  import("./pages/dashboard/MyInstance/MyInstance")
);
const Machines = lazy(() => import("./pages/dashboard/Machines/Machines"));
const Earning = lazy(() => import("./pages/dashboard/Earning/Earning"));
const Instance = lazy(() => import("./pages/dashboard/instance/Instance"));
const InstanceParent = lazy(() =>
  import("./pages/dashboard/instance/InstanceParent")
);
const TemplateParent = lazy(() =>
  import("./pages/dashboard/Template/TemplateParent")
);
const Render = lazy(() => import("./pages/dashboard/Render/Render"));
const RenderListing = lazy(() =>
  import("./pages/dashboard/Render/pages/RenderListing/RenderListing")
);

const Upload = lazy(() =>
  import("./pages/dashboard/Render/pages/Upload/Upload")
);
const Process = lazy(() =>
  import("./pages/dashboard/Render/pages/Process/Process")
);
const Config = lazy(() =>
  import("./pages/dashboard/Render/pages/Configuration/Configuration")
);

const Template = lazy(() => import("./pages/dashboard/Template/Template"));
const TemplateEdit = lazy(() =>
  import("./pages/dashboard/TemplateEdit/TemplateEdit")
);
const TemplateView = lazy(() =>
  import("./pages/dashboard/TemplateView/TemplateView")
);
const Payment = lazy(() =>
  import("./pages/dashboard/instance/Payment/Payment")
);
const Account = lazy(() => import("./pages/dashboard/Account/Account"));
const HowToUse = lazy(() => import("./pages/dashboard/HowToUse/HowToUse"));

const Transaction = lazy(() =>
  import("./pages/dashboard/Transaction/Transaction")
);
const HostDashboard = lazy(() =>
  import("./pages/dashboard/HostDashboard/HostDashboard")
);
const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<Loading theme={"dark"} />}>
        <Dashboard />
      </Suspense>
    ),
    // children: [
    // {
    //   index: true,
    //   element: (
    //     <Suspense fallback={<Loading theme={"dark"} />}>
    //       <MainLanding />
    //     </Suspense>
    //   ),
    // },
    // ],
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loading />}>
            <InstanceParent />
          </Suspense>
        ),
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<Loading />}>
            <HostDashboard />
          </Suspense>
        ),
      },
      {
        path: "instance",
        element: (
          <Suspense fallback={<Loading />}>
            <InstanceParent />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />}>
                <Instance />
              </Suspense>
            ),
          },

          {
            path: "payment",
            element: (
              <Suspense fallback={<Loading />}>
                <Payment />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "myinstance",
        element: (
          <Suspense fallback={<Loading />}>
            <MyInstance />
          </Suspense>
        ),
      },
      {
        path: "earning",
        element: (
          <Suspense fallback={<Loading />}>
            <Earning />
          </Suspense>
        ),
      },
      {
        path: "prompt",
        element: (
          <Suspense fallback={<Loading />}>
            <Prompt />
          </Suspense>
        ),
      },
      {
        path: "render",
        element: (
          <Suspense fallback={<Loading />}>
            <Render />
          </Suspense>
        ),
        children: [
          {
            path: "listing",
            element: (
              <Suspense fallback={<Loading />}>
                <RenderListing />
              </Suspense>
            ),
          },
          {
            path: "",
            element: (
              <Suspense fallback={<Loading />}>
                <Upload />
              </Suspense>
            ),
          },
          {
            path: "config/:id",
            element: (
              <Suspense fallback={<Loading />}>
                <Config />
              </Suspense>
            ),
          },
          {
            path: "process/:id",
            element: (
              <Suspense fallback={<Loading />}>
                <Process />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "template",
        element: (
          <Suspense fallback={<Loading />}>
            <TemplateParent />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Loading />}>
                <Template />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<Loading />}>
                <TemplateEdit />
              </Suspense>
            ),
          },
          {
            path: "view/:id",
            element: (
              <Suspense fallback={<Loading />}>
                <TemplateView />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "account",
        element: (
          <Suspense fallback={<Loading />}>
            <Account />
          </Suspense>
        ),
      },
      {
        path: "how-to-use",
        element: (
          <Suspense fallback={<Loading />}>
            <HowToUse />
          </Suspense>
        ),
      },
      {
        path: "transaction",
        element: (
          <Suspense fallback={<Loading />}>
            <Transaction />
          </Suspense>
        ),
      },
      {
        path: "machines",
        element: (
          <Suspense fallback={<Loading />}>
            <Machines />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/instance" replace />,
  },
  // {
  //   path: "auth",
  //   element: (
  //     <Suspense fallback={<Loading />}>
  //       <Authentication />
  //     </Suspense>
  //   ),
  //   children: [
  //     {
  //       index: true,
  //       element: (
  //         <Suspense>
  //           <Login />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "register",
  //       element: (
  //         <Suspense>
  //           <Register />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "forgot",
  //       element: (
  //         <Suspense>
  //           <Forgot />
  //         </Suspense>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   path: "console",
  //   element: <Dashboard />,
  //   children: [
  //     {
  //       path: "instance",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <InstanceParent />
  //         </Suspense>
  //       ),
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense fallback={<Loading />}>
  //               <Instance />
  //             </Suspense>
  //           ),
  //         },

  //         {
  //           path: "payment",
  //           element: (
  //             <Suspense fallback={<Loading />}>
  //               <Payment />
  //             </Suspense>
  //           ),
  //         },
  //       ],
  //     },
  //     {
  //       path: "myinstance",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <MyInstance />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "earning",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <Earning />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "prompt",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <Prompt />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "template",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <TemplateParent />
  //         </Suspense>
  //       ),
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense fallback={<Loading />}>
  //               <Template />
  //             </Suspense>
  //           ),
  //         },
  //         {
  //           path: ":id",
  //           element: (
  //             <Suspense fallback={<Loading />}>
  //               <TemplateEdit />
  //             </Suspense>
  //           ),
  //         },
  //         {
  //           path: "view/:id",
  //           element: (
  //             <Suspense fallback={<Loading />}>
  //               <TemplateView />
  //             </Suspense>
  //           ),
  //         },
  //       ],
  //     },
  //     {
  //       path: "account",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <Account />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "how-to-use",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <HowToUse />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "transaction",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <Transaction />
  //         </Suspense>
  //       ),
  //     },
  //     {
  //       path: "machines",
  //       element: (
  //         <Suspense fallback={<Loading />}>
  //           <Machines />
  //         </Suspense>
  //       ),
  //     },
  //   ],
  // },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
