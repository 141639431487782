import React from "react";
import classes from "./Forgot.module.css";
import Input from "../../../UI/Input/Input";
import Label from "../../../UI/Label/Label";
import Button from "../../../UI/Button/Button";

const Forgot = ({ goTo }) => {
  const handleSubmit = () => {};
  return (
    <>
      <div className={classes.head}>Create new password</div>
      <div className={classes.modbody}>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="password" value="New Password" />
            <Input
              name="password"
              type="password"
              placeholder="Enter password"
            />
          </div>
        </div>
        <div className={classes.detail}>
          <div className={classes.detFull}>
            <Label htmlFor="password2" value="Confirm Password" />
            <Input
              name="password2"
              type="password"
              placeholder="Re-enter password"
            />
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <Button full={true} onClick={handleSubmit}>
            Login
          </Button>
        </div>
        <div className={classes.option}>
          Back to
          <span
            onClick={() => {
              goTo("login");
            }}
          >
            {" "}Login
          </span>
        </div>
      </div>
    </>
  );
};

export default Forgot;
