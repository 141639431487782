import { api } from "./api";

export const instanceActionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    action: builder.mutation({
      query: (credentials) => ({
        url: "/api/container",
        method: "POST",
        body: credentials,
      }),
    }),
    setupSSH: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/ssh_key",
        method: "PUT",
        body: credentials,
      }),
    }),
    updateProfile: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/update-profile",
        method: "PUT",
        body: credentials,
      }),
    }),
    getProfile: builder.query({
      query: () => `/api/user/details`,
      transformResponse: (responseData) => {
        return responseData ? responseData.data : [];
      },
    }),
    updatePassword: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/update-password",
        method: "PUT",
        body: credentials,
      }),
    }),
    updateApiKey: builder.mutation({
      query: (credentials) => ({
        url: "/api/user/generate-api-key",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useActionMutation,
  useSetupSSHMutation,
  useUpdateProfileMutation,
  useLazyGetProfileQuery,
  useUpdatePasswordMutation,
  useUpdateApiKeyMutation,
} = instanceActionApi;
