import React from "react";
import classes from "./Button.module.css";

const Button = ({ children, onClick, disabled = false, full = false }) => {
  return (
    <div
      className={`${classes.button} ${disabled && classes.disabled} ${
        full && classes.full
      }`}
      onClick={disabled ? () => {} : onClick}
    >
      {children}
    </div>
  );
};

export default Button;
