import React, { useRef } from "react";
import classes from "./OtpInput.module.css";

const OtpInput = ({ length = 6 }) => {
  // Initialize an array to store refs for each input element
  const inputs = [];

  // Function to handle input change
  const handleInputChange = (index, value) => {
    if (value.length === 1 && index < length - 1) {
      // Move focus to the next input if a value is entered
      inputs[index + 1].focus();
    }
  };

  // Function to handle paste event
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    for (let i = 0; i < Math.min(pastedData.length, length); i++) {
      inputs[i].value = pastedData[i];
      handleInputChange(i, pastedData[i]);
    }
  };

  return (
    <div className={classes.otpWrap}>
      {[...Array(length)].map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          className={classes.otpInput}
          ref={(input) => {
            inputs[index] = input;
          }} // Assign ref to each input element
          onChange={(e) => handleInputChange(index, e.target.value)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default OtpInput;
