import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { showToast, hideToastById } from "../../store/toast";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const token = state.auth?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: (response) => response.json(),
});

const baseQueryWithRefreshToken = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error) {
      if (result.error.status === 403) {
        const refreshResult = await baseQuery(
          { url: "/api/refresh", method: "GET" },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          result = await baseQuery(args, api, extraOptions);
        } else {
          // api.dispatch(logout());
          handleError(refreshResult.error, api.dispatch);
          return { error: refreshResult.error };
        }
      } else {
        handleError(result.error, api.dispatch);
        return { error: result.error };
      }
    }
    api.dispatch(hideToastById(10));
    return result.error ? { error: result.error } : { data: result.data };
  } catch (error) {
    const fetchError = { status: "FETCH_ERROR", error: error.message };
    handleError(fetchError, api.dispatch);
    return { error: fetchError };
  }
};

const handleError = (error, dispatchMethod) => {
  // Extract and format the error message properly
  const errorMessage =
    error?.data?.error ||
    error?.error ||
    error?.message ||
    "Something went wrong";

  dispatchMethod(hideToastById(10));
  dispatchMethod(
    showToast({
      message: errorMessage,
      type: "error",
      timeout: 5000,
    })
  );
};

export const api = createApi({
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: ["Host", "Template"],
  endpoints: (builder) => ({}),
});
