import React, { useEffect, useState } from "react";
import classes from "./PageAnimation.module.css";

const PageAnimation = ({ children }) => {
  const [PageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => {
      setPageLoaded(true);
    });
  }, []);

  return (
    <div
      className={`${classes.animation} ${PageLoaded && classes.animationClass}`}
    >
      {children}
    </div>
  );
};

export default PageAnimation;
