import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
};

const toggleSlice = createSlice({
  name: "toggle",
  initialState: initialState,
  reducers: {
    toggle(state) {
      state.darkMode = !state.darkMode;
    },
    setCurrentTheme(state, actions) {
      state.darkMode = actions.payload;
    },
  },
});

export const toggleActions = toggleSlice.actions;

export default toggleSlice.reducer;
