import { api } from "./api";

export const walletApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: () => `/api/transaction?page=0&page_size=1000`,
      transformResponse: (responseData) => {
        return responseData ? responseData.data : [];
      },
    }),
    getBalance: builder.mutation({
      query: (credentials) => ({
        url: "/api/transaction/balance",
        method: "POST",
        body: credentials,
      }),
    }),
    updateTransaction: builder.mutation({
      query: (credentials) => ({
        url: "/api/transaction",
        method: "POST",
        body: credentials,
      }),
    }),
    updateWallet: builder.mutation({
      query: (credentials) => ({
        url: "/api/wallet",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useGetBalanceMutation,
  useUpdateTransactionMutation,
  useUpdateWalletMutation,
  useLazyGetTransactionsQuery,
} = walletApi;
